/* Carousel*/
header {
    .carousel {
        .carousel-inner {
            .carousel-item {
                .view {
                    @extend .h-100;
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-position: center center;
                }
                .carousel-caption {
                    max-width: 1140px;
                    margin: 0 auto;
                    @extend .white-text;
                    @extend .p-4;
                    @extend .text-left;
                    h1.ernryd-header, h2.ernryd-header, h3.ernryd-header {
                        text-shadow: 1px 1px 10px #1e1e1c;
                    }
                }
            }
        }
    }
}
