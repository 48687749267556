/* billistan */
section#carsort {
    .container {
        .card {
            .card-header {
                border-bottom: none;
            }
        }
        .filter {
            @include make-row();
            @extend .row-cols-1;
            @extend .row-cols-md-2;
            @extend .text-center;
            > div {
                @include make-col-ready();
            }
            .btn-group-label {
                @extend .btn-group;
            }
            .btn-group {
                .filterbtn {
                    @extend .btn;
                    @extend .btn-primary;
                    @include media-breakpoint-down(md) {
                        padding: 0.5rem;
                        margin: 6px 6px;
                    }
                }
            }
        }
        .sort-by-button-group {
            .btn-group {
                .btn-sort {
                    @extend .btn;
                    @extend .btn-primary;
                    margin-left: 0;
                    margin-right: 0;
                    @include media-breakpoint-down(md) {
                        padding: 0.5rem;
                        margin: 6px 0;
                    }
                }
            }
        }
        @include media-breakpoint-down(md) {
            padding: 0;
        }
    }
}
section#bilar {
    .cars, .container {
        .grid {
            text-align: justify;
            @extend .row;
            @extend .d-flex;
            @extend .justify-content-center;
            .mix {
                display: flex;
                flex-flow: column;
                @include make-col-ready();
                @include media-breakpoint-up(md) {
                    @include make-col(6);
                }
                @include media-breakpoint-up(xl) {
                    @include make-col(4);
                }
                @include media-breakpoint-up(xxxl) {
                    @include make-col(3);
                }
                a.carcontainer {
                    @extend .d-flex;
                    @extend .flex-column;
                    flex-grow: 1;
                    color: black;
                    display: block;
                    max-width: none;
                    width: 100%;
                    padding: 0;
                    @extend  .z-depth-1;
                    .carimage {
                        position: relative;
                        overflow: hidden;
                        .carplace {
                            @extend .z-depth-1;
                            background: $brand-color;
                            color: rgb(255, 255, 255);
                            padding: 0;
                            text-align: center;
                            font-weight: 600;
                            position: absolute;
                            text-transform: uppercase;
                            font-weight: bold;
                            width: 220px;
                            -webkit-transform: rotate(-45deg);
                            -moz-transform: rotate(-45deg);
                            -ms-transform: rotate(-45deg);
                            -o-transform: rotate(-45deg);
                            transform: rotate(-45deg);
                            top: 35px;
                            left: -65px;
                        }
                    }
                    .carinfo {
                        @extend .d-flex;
                        @extend .flex-column;
                        @extend .flex-fill;
                        h4 {
                            text-align: left;
                            line-height: 2rem;
                            overflow: auto;
                            height: auto;
                            white-space: unset!important;
                        }
                        .data {
                            @extend .d-flex;                            
                            @extend .flex-column;
                            @extend .justify-content-center;
                            margin-top:auto;
                            dl.dl-horizontal {
                                max-width: 250px;
                            }
                            div {
                                @extend .text-center;
                            }
                            i.fa-info-circle {
                                color: $brand-color;
                                font-size: 130%;
                            }
                        }
                    }
                }
                //optional look with hover effect on desktop - set on carcontainer a
                &.infohover {
                    position: relative;
                    width: 100%;
                    @include media-breakpoint-up(md) {
                        position: relative;
                        max-width: none;
                        width: 100%;
                        a {
                            max-width: 100%;
                            background-color: #000;
                            .carinfo {
                                display: none;
                                position: absolute;
                                top: 0;
                                width: 100%;
                                max-width: 100%;
                                max-height: 100%;
                                overflow: hidden;
                                color: white;
                                text-shadow: 2px 2px 2px #000;
                                @include media-breakpoint-down(lg) {
                                    font-size: 80%;
                                }
                            }
                            .carimage {
                                display: block;
                                width: 100%;
                                opacity: 1;
                                transition: opacity .55s ease-in-out;
                            }
                            &:hover {
                                .carinfo {
                                    display: block;
                                }
                                .carimage {
                                    opacity: 0.3;
                                    transition: opacity .55s ease-in-out;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
