.header-logo {
  display: block;
  max-width: 281px;
  height: 75px;
  text-indent: -9999px;
  //background-image: url(../../img/logos/logotype2.png);
  //background-position: center;
  //background-size: contain;
  //background-repeat: no-repeat;
}
.top-logos {
  img {
    @extend .img-fluid;
  }
}
body {
    header {
        .view {
            height: 40vh;
            .intro-info-content {
                position: absolute;
                max-width: 1110px;
                bottom: 60px;
                text-shadow: 2px 2px 3px #000;
            }
            .bgimage {
                background-size: cover;
                background-position: center;
                position: absolute;
                width: 100%;
                height: 100%;
                &.blur {
                    transform: scale(1.05);
                    filter: blur(5px);
                }
            }
            .intro-logo {
                padding: 5px;
                background-color: white;
                border-radius: 5px;
            }
            .jarallax {
                min-height: auto;
            }
        }
        .carousel-item .view {
            height: 50vh;
        }
    }
    main {
        margin-top: 50px;
    }
}
/* purgecss start ignore */
body.index {
    header {
        height: 90vh;
    }
    main {
        margin-top: 0;
        @include media-breakpoint-up(md) {
            margin-top: 0px;
        }
    }
}
/* purgecss end ignore */