body {
    #footer {
        svg {
            fill: currentColor;
        }
        &.page-footer {
            margin-top: 0;
            p {
                font-size: 0.9rem;
            }
            .footer-copyright {
                a {
                    color: $white-base;
                }
            }
        }
        .jarallax {
            min-height: 0;
        }
        .table {
            color: white;
        }
        table.openhours {
            //@extend .white-text;
            max-width: 300px;
            margin-bottom: 10px;
            @include media-breakpoint-down(sm) {
                margin: 0 auto;
            }
            td {
                font-weight: inherit;
            }
            td:first-of-type {
                width: 130px;
            }
        }
        ul.contact-informations {
            font-size: 0.9rem;
            &.fa-ul {
                margin-left: 3em;
            }
        }
        .fwmap {
            @extend .container-fluid;
            padding: 0;
        }
    }
}