body {
    .theme-skin {
        .navbar .navbar-nav .nav-item .dropdown-menu a {
            color: white;
        }
    }
    .navbar {
        /* Navbar animation */
        &:not(.top-nav-collapse) {
            background: transparent;
        }
        
        @media (max-width: 768px) {
            &:not(.top-nav-collapse) {
                background: #7d8488;
            }
        }
        
        @media (min-width: 769px) {
            &:not(.top-nav-collapse) {
                box-shadow: none;
            }
        }
        /* Adding color to the Navbar on mobile */
        &.scrolling-navbar {
            background: radial-gradient(ellipse at center,rgba(55,68,76,0.9) 10%,rgba(22,26,30,0.9) 100%);
            &.top-nav-collapse {
                background-color: rgba(31, 40, 65, 1);
            }
        }
        &:not(.top-nav-collapse) {
            box-shadow: none;
        }

        .navbar-nav {
            .nav-item {
                &.active {
                    .nav-link {
                        background-color: transparent!important;
                        border-bottom: 4px solid $brand-color;
                    }
                }
                .dropdown-menu {
                    background-color: $brand-color;    
                    @include media-breakpoint-up(lg) {
                        background-color: rgba(2, 113, 132, 0.9);
                    }
                    a.dropdown-item {
                        color: white;
                        &.active {
                            background-color: $brand-color;
                            color: grey;
                        }
                    }
                }
            }
            &.nav-flex-icons {
                .nav-item {
                    font-size: 1.3rem;
                    padding: 0 3px;
                }
            }
        }
        .navbar-brand {
            max-width: 250px;
            color: white;
            img {
                -webkit-filter: drop-shadow(12px 12px 7px rgba(0,0,0,0.5));      
                filter: drop-shadow(12px 12px 7px rgba(0,0,0,0.5));
            }
            svg {
                height: 55px;
                fill: currentColor;
            }
        }
        .logo {
            img {
                max-width: 100%;
                padding: 5px;
            }
        }
    }
}