// Your custom variables
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1366px,
  xxxl: 1920px
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px
);
@import url('https://fonts.googleapis.com/css2?family=Russo+One&display=swap');
@font-face {
    font-family: 'Conifer-Rough';
    font-display: auto; /* or block, swap, fallback, optional */
    src: url('../fonts/2D1412_1_0.eot');
    src: local('Conifer-Rough'),
        url('../fonts/2D1412_1_0.woff2') format('woff2'),
        url('../fonts/2D1412_1_0.woff') format('woff'),
        url('../fonts/2D1412_1_0.ttf') format('truetype'),
        url('../fonts/2D1412_1_0.eot?#iefix') format('embedded-opentype');
}
/* @font-face {
    font-family: 'Conifer-Solid';
    src: url('../fonts/2D1412_0_0.eot');
    src: url('../fonts/2D1412_0_0.eot?#iefix') format('embedded-opentype'),url('../fonts/2D1412_0_0.woff2') format('woff2'),url('../fonts/2D1412_0_0.woff') format('woff'),url('../fonts/2D1412_0_0.ttf') format('truetype');
} */

$headings-font-family: 'Russo One', sans-serif;
$brand-color: #027184;
$theme-colors: (
  "primary": rgb(40, 40, 40),
  "brand": #027184
);
