form {
    .form-group {
        @extend .md-form;
        p.help-block.form-field-error-label {
            @extend .small;
            @extend .red-text;
        }
    }
    .invalid-feedback {
        &.visible {
            display: block;
        }
    }
    .alert {
        @extend .text-center;
        p {
            margin: 0;
        }
    }
    .mandatory-fields-text {
        @extend .small;
        span {
            color: red;
            font-size: 120%;
        }
    }
    span.mandatory {
        color: red;
    }
    // brand-color on forms focus and active
    .form-check-input[type="radio"]:checked + label:after,
    .form-check-input[type="radio"].with-gap:checked + label:before,
    .form-check-input[type="radio"].with-gap:checked + label:after,
    label.btn input[type="radio"]:checked + label:after,
    label.btn input[type="radio"].with-gap:checked + label:before,
    label.btn input[type="radio"].with-gap:checked + label:after {
        border: 2px solid $brand-color;
    }

    .form-check-input[type="radio"]:checked + label:after,
    .form-check-input[type="radio"].with-gap:checked + label:after,
    label.btn input[type="radio"]:checked + label:after,
    label.btn input[type="radio"].with-gap:checked + label:after {
        background-color: $brand-color;
    }
    .mdb-select {
        .dropdown-content {
            li:not(.disabled) > span {
                color: $brand-color;
            }
        }
    }
    .md-form {
        input:focus,
        textarea:focus,
        .select-wrapper input.select-dropdown {
            box-shadow: 0 1px 0 0 $brand-color !important;
            border-bottom: 1px solid $brand-color !important;
        }
        label,
        i.prefix {
            &.active {
                color: $brand-color !important;
            }
        }
    }
    /*   .g-recaptcha {
    transform:scale(0.85);
    -webkit-transform:scale(0.85);
    transform-origin:0 0;
    -webkit-transform-origin:0 0;
  } */
}
.stripe-loading-indicator .stripe,
.stripe-loading-indicator .stripe-loaded {
    background: $blue-base;
}
// kontaktform
#kontaktForm.g-recaptcha > div {
    margin: 0 auto;
}
// provkörning
.provk.form {
    form {
        input,
        select {
            font-size: 0.8rem;
        }
        label {
            font-size: 0.8rem;
            margin-bottom: 5px;
        }
        .form-control.datepicker[readonly] {
            background-color: transparent !important;
        }
        .mandatory-fields-text {
            font-size: 0.7rem;
        }
    }
}
// verkstadsoffert
.offer.form {
    form {
        h2 {
            @extend .h3-responsive;
        }
        .custom-checkbox {
            @extend .form-check;
            @extend .form-check-inline;
            label.form-check-label {
                height: auto;
            }
        }
        .single-service {
            position: relative;
            //padding-left: 80px;
            margin-bottom: 20px;
            > img {
                position: absolute;
                left: -10px;
                top: 5px;
            }
            .custom-checkbox {
                a {
                    i {
                        @extend .grey-text;
                    }
                }
            }
        }
        .serviceinfo {
            @include make-row();
            display: flex;
            @extend .row-cols-1;
            @extend .row-cols-md-2;
            @extend .my-5;
            @extend .p-3;
            @extend .border;
            @extend .rounded;
            @extend .grey.lighten-3;
            .col {
                @extend .mb-3;
            }
            .input-group.regno {
                max-width: 180px;
                .input-group-text {
                    background-image: url(../img/bg/reg-nr-bg.png);
                    background-repeat: no-repeat;
                    background-position: 1px 1px;
                }
                input {
                    height: 50px;
                    text-transform: uppercase;
                    font-size: 30px;
                }
            }
        }
    }
}
