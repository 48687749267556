/* purgecss start ignore */

$slick-font-family: "Font Awesome 5 Free";
$slick-prev-character: "\f053";
$slick-next-character: "\f054";
$slick-dot-character: "\f111";
$slick-loader-path: "../img/overlays/";
@import "../node_modules/slick-carousel/slick/slick.scss";
@import "../node_modules/slick-carousel/slick/slick-theme.scss";


.slider, .slidernav, .slick-list {
    .slick-slide {
        position: relative;
        max-width: 100%;
        height: auto!important;
        margin: 0px;
        .image {
            width: 100%;
            padding-bottom: 66.5%;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
        }
        a {
            .slider-fullscreen {
                position: absolute;
                right: 40%;
                bottom: 0;
                display: -ms-flexbox;
                display: flex;
                -ms-flex-align: center;
                align-items: center;
                -ms-flex-pack: center;
                justify-content: center;
                height: 100%;
                opacity: 0.7;
                padding: 5px 10px;
                color: white;
                i {
                    text-shadow: none;
                    transition: all .2s ease-in-out;
                }
                &:hover {
                    opacity: 1;
                    i {
                        text-shadow: 2px 2px 2px #000;
                    }
                }
            }
        }
    }
}
.slider {
    .slick-list {
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    }
}
.slidernav {
    .slick-slide {
        .image {
            background-size: cover;
        }
        opacity: $slick-opacity-default;
        &.slick-current {
            opacity: $slick-opacity-on-hover;
            box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
            .image:before {
                background: $brand-color;
                height: 4px;
                width: 100%;
                left: 0;
                right: 0;
                bottom: 0;
                content: "";
                position: absolute;
            }
        }
    }
}

.slick-slider {
    .slick-prev, .slick-next {
        position: absolute;
        bottom: 0;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-pack: center;
        justify-content: center;
        width: 15%;
        height: 100%;
        color: #fff;
        text-align: center;
        opacity: .5;
        cursor: pointer;
        text-decoration: none;
        transition: all .2s ease-in-out;
        z-index: 100;
        &:before {
            font-size: 3rem;
        }
        &:hover {
            color: white;
            opacity: 1;
            text-shadow: 2px 2px 2px #000;
            transition: all .2s ease-in-out;
        }
    }
    .slick-prev {
        left:0;
    }
    .slick-next {
        right: 0;
    }
    .slick-dots {
        bottom: 0;
        li button:before {
            color: #ffffff;
        }
    }
}

// FP-slider bilar
section.bilar.slick-initialized {
    .slick-prev:before, .slick-next:before {
        color: grey;
    }
    .slick-slide {
        margin: 5px;
        .carcontainer {
            margin: 0px;
            a {
                display: block;
                box-shadow: 0px 0px 2px #ccc;
                color: #000;
                .carimage {
                    width: 100%;
                    padding-bottom: 70%;
                    background-size: cover;
                    background-position: center;
                    background-repeat: no-repeat;
                    position: relative;
                    overflow: hidden;
                    .carplace {
                        background: $brand-color;
                        color: rgb(255, 255, 255);
                        padding: 0;
                        text-align: center;
                        font-weight: 600;
                        position: absolute;
                        text-transform: uppercase;
                        font-weight: bold;
                        width: 220px;
                        -webkit-transform: rotate(-45deg);
                        -moz-transform: rotate(-45deg);
                        -ms-transform: rotate(-45deg);
                        -o-transform: rotate(-45deg);
                        transform: rotate(-45deg);
                        top: 35px;
                        left: -65px;
                        &.haninge {
                            background: $grey-darken-3;
                        }
                    }
                }
                h4 {
                    text-align: center;
                    max-width: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    height: 2rem;
                    line-height: 2rem;
                    white-space: nowrap!important;
                }
                .carinfo {
                    padding: 10px;
                    .btn, .price {
                        margin-top: 10px;
                    }
                    .price {
                        font-size: 80%;
                        padding: 5px;
                        border: 1px solid rgb(204, 204, 204);
                        border-radius: 5px;
                    }
                    .price span {
                        font-weight: bold;
                        font-size: 1rem;
                    }
                    i {
                        font-size: 1rem;
                        .fa-info-circle {
                            position: relative;
                            color: grey;
                            left: 5px;
                            margin-left: 20px;
                        }
                        .fa-arrow-down {
                            color: red;
                            margin-right: 20px;
                        }
                    }
                    .dl-horizontal {
                        margin-bottom: 10px;
                        width: 80%;
                        margin: auto;
                    }
                    dt, dd {
                        width: 50% !important;
                        float: left;
                        margin: 0;
                    }
                    dl dt {
                        text-align: left;
                    }
                    dl dd {
                        text-align: right;
                    }
                }
                //optional look with hover effect on desktop - set on carcontainer a
                &.infohover {
                  @include media-breakpoint-up(md) {
                    background-color: #000;
                    .carinfo {
                      display: none;
                      position: absolute;
                      top: 0;
                      max-width: 100%;
                      max-height: 100%;
                      width: 100%;
                      overflow: hidden;
                      color: white;
                      text-shadow: 2px 2px 2px #000;
                    }
                    .carimage {
                      opacity: 1;
                      transition: opacity .55s ease-in-out;
                    }
                    &:hover {
                      .carinfo {
                        display: block;
                      }
                      .carimage {
                        opacity: 0.3;
                        transition: opacity .55s ease-in-out;
                      }
                    }
                  }
                }
            }
        }
    }
}
/* purgecss end ignore */