#personal {
    .column {
        @include make-col-ready();
        @include media-breakpoint-up(md) {
            @include make-col(6);
        }
        @include media-breakpoint-down(sm) {
            @include make-col(12);
        }
        .person {
            @extend .mb-4;
            @extend .mx-4;
            .avatar {
                @extend .mb-4;
                img {
                    @extend .z-depth-1;
                }
            }
            .info {
                h4 {
                    @extend .h4-responsive;
                }
            }
        }
    }
}
.aboutus {
    #personal {
        .column {
            @include media-breakpoint-up(md) {
                @include make-col(6);
            }
            @include media-breakpoint-down(sm) {
                @include make-col(12);
            }
            .person {
                .avatar {
                    @extend .mb-4;
                }
                @extend .mb-4;
                @extend .mx-4;
            }
        }
    }
}
