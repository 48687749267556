/* bilsidan */
body.bil {
    header {
        @include media-breakpoint-down(xxl) {
            .view {
                height: 55vh;
            }
        }
        @include media-breakpoint-down(md) {
            height: 80vh;
            .view {
                height: 100%;
            }
        }
    }
    .jarallax {
        min-height: 100%;
    }
}

section#bil {
    .price-overlay {
        @extend .d-none;
        @extend .d-md-block;
    }
    .col.slider {
        @include media-breakpoint-down(md) {
            padding: 0;
        }
    }
    .buttonblock {
        @extend .card-body;
        @extend .d-print-none;
        .btn {
            @extend .btn-lg;
            @extend .btn-block;
            @extend .btn-primary;
            margin-top: 0.5rem;
            i {
                @extend .mr-2;
            }
        }
        > div {
            @extend .text-center;
        }
    }
    .footerbuttons {
        @include make-row();
        div {
            @include make-col-ready();
            @include make-col(6)
            @include media-breakpoint-down(md) {
                @include make-col(12);
            }
            a, button {
                @extend .btn;
                @extend .btn-primary;
                @extend .btn-block;
                @extend .btn-lg;
                @extend .mb-2;
                i {
                    @extend .mr-2;
                }
            }
        }
    }
    .objekt-parameters {
        @include make-col-ready();
        @extend .col-12;
        @extend .col-md-6;
        @extend .col-lg-4;
        @media print {
            @include make-col(4);
        }
    }
    .objekt-utr {
        @include make-col-ready();
        @include media-breakpoint-up(lg) {
            @include make-col(8);
        }
        //@extend .order-sm-3;
        //@extend .order-md-4;
        //@extend .order-lg-3;
        @extend .mb-3;
        font-size: 0.9rem;
        ul {
            li.list-item {
                @extend .col-xl-4;
                @extend .col-md-6;
                @extend .col-12;
            }
        }
        @include media-breakpoint-up(sm) {
            display: block;
        }
        @media print {
            display: block;
            @include make-col(12);
            ul {
                li.list-item {
                    @include make-col(4);
                }
            }
        }
    }
}
