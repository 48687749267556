@charset "UTF-8";

$fa-font-path: "../fonts";
@import "node_modules/@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "node_modules/@fortawesome/fontawesome-free/scss/solid.scss";
@import "node_modules/@fortawesome/fontawesome-free/scss/brands.scss";

//@import "node_modules/@fancyapps/fancybox/dist/jquery.fancybox.css";

@import "node_modules/mdbootstrap-pro/scss/core/bootstrap/functions";
@import "node_modules/mdbootstrap-pro/scss/core/bootstrap/variables";

@import "_custom-variables";
@import "node_modules/bootstrap/scss/bootstrap.scss";

// Bootstrap
//@import "node_modules/mdbootstrap-pro/scss/core/bootstrap/functions";
//@import "node_modules/mdbootstrap-pro/scss/core/bootstrap/variables";
//@import "node_modules/mdbootstrap-pro/scss/core/bootstrap/rfs";

// CORE
@import "node_modules/mdbootstrap-pro/scss/core/mixins";
// Your custom variables
@import "node_modules/mdbootstrap-pro/scss/custom-variables";
@import "node_modules/mdbootstrap-pro/scss/core/colors";
@import "node_modules/mdbootstrap-pro/scss/core/variables";
@import "node_modules/mdbootstrap-pro/scss/core/global";
@import "node_modules/mdbootstrap-pro/scss/core/helpers";
@import "node_modules/mdbootstrap-pro/scss/core/typography";
@import "node_modules/mdbootstrap-pro/scss/core/masks";
@import "node_modules/mdbootstrap-pro/scss/core/waves";

// FREE
/* purgecss start ignore */
@import "node_modules/mdbootstrap-pro/scss/free/animations-basic";
@import "node_modules/mdbootstrap-pro/scss/free/modules/animations-extended/module";
/* purgecss end ignore */
@import "node_modules/mdbootstrap-pro/scss/free/buttons";
@import "node_modules/mdbootstrap-pro/scss/free/cards";
@import "node_modules/mdbootstrap-pro/scss/free/dropdowns";
@import "node_modules/mdbootstrap-pro/scss/free/input-group";
@import "node_modules/mdbootstrap-pro/scss/free/navbars";
@import "node_modules/mdbootstrap-pro/scss/free/pagination";
@import "node_modules/mdbootstrap-pro/scss/free/badges";
@import "node_modules/mdbootstrap-pro/scss/free/modals";
@import "node_modules/mdbootstrap-pro/scss/free/carousels";
@import "node_modules/mdbootstrap-pro/scss/free/forms";
@import "node_modules/mdbootstrap-pro/scss/free/msc";
@import "node_modules/mdbootstrap-pro/scss/free/footers";
@import "node_modules/mdbootstrap-pro/scss/free/list-group";
@import "node_modules/mdbootstrap-pro/scss/free/tables";
@import "node_modules/mdbootstrap-pro/scss/free/depreciated";
@import "node_modules/mdbootstrap-pro/scss/free/steppers";
@import "node_modules/mdbootstrap-pro/scss/free/loader";
@import "node_modules/mdbootstrap-pro/scss/free/treeview";

// PRO
@import "node_modules/mdbootstrap-pro/scss/pro/variables";
@import "node_modules/mdbootstrap-pro/scss/pro/buttons";
@import "node_modules/mdbootstrap-pro/scss/pro/social-buttons";
@import "node_modules/mdbootstrap-pro/scss/pro/tabs";
@import "node_modules/mdbootstrap-pro/scss/pro/cards-basic";
@import "node_modules/mdbootstrap-pro/scss/pro/dropdowns";
@import "node_modules/mdbootstrap-pro/scss/pro/navbars";
@import "node_modules/mdbootstrap-pro/scss/pro/scrollspy";
@import "node_modules/mdbootstrap-pro/scss/pro/modules/lightbox/module";
@import "node_modules/mdbootstrap-pro/scss/pro/chips";
@import "node_modules/mdbootstrap-pro/scss/pro/msc";
@import "node_modules/mdbootstrap-pro/scss/pro/forms";
@import "node_modules/mdbootstrap-pro/scss/pro/radio";
@import "node_modules/mdbootstrap-pro/scss/pro/checkbox";
@import "node_modules/mdbootstrap-pro/scss/pro/material-select";
@import "node_modules/mdbootstrap-pro/scss/pro/switch";
@import "node_modules/mdbootstrap-pro/scss/pro/file-input";
@import "node_modules/mdbootstrap-pro/scss/pro/range";
@import "node_modules/mdbootstrap-pro/scss/pro/input-group";
@import "node_modules/mdbootstrap-pro/scss/pro/autocomplete";
@import "node_modules/mdbootstrap-pro/scss/pro/accordion-basic";
@import "node_modules/mdbootstrap-pro/scss/pro/modules/accordion-extended/module";
@import "node_modules/mdbootstrap-pro/scss/pro/modules/parallax/module";
@import "node_modules/mdbootstrap-pro/scss/pro/sidenav";
@import "node_modules/mdbootstrap-pro/scss/pro/ecommerce";
@import "node_modules/mdbootstrap-pro/scss/pro/carousels";
@import "node_modules/mdbootstrap-pro/scss/pro/blog";
@import "node_modules/mdbootstrap-pro/scss/pro/toasts";
/* purgecss start ignore */
@import "node_modules/mdbootstrap-pro/scss/pro/animations";
/* purgecss end ignore */
@import "node_modules/mdbootstrap-pro/scss/pro/progress";
@import "node_modules/mdbootstrap-pro/scss/pro/scrollbar";
@import "node_modules/mdbootstrap-pro/scss/pro/modules/megamenu/module";
@import "node_modules/mdbootstrap-pro/scss/pro/tooltips";

// Your custom skin
@import "_custom-skin";
@import "node_modules/mdbootstrap-pro/scss/pro/skins";
@import "node_modules/mdbootstrap-pro/scss/pro/depreciated";

// Picker
@import "node_modules/mdbootstrap-pro/scss/pro/picker/default";
//@import "pro/picker/default-time";
@import "node_modules/mdbootstrap-pro/scss/pro/picker/default-date";

// Sections
@import "node_modules/mdbootstrap-pro/scss/pro/sections/templates";
//@import "node_modules/mdbootstrap-pro/scss/pro/sections/social";
//@import "node_modules/mdbootstrap-pro/scss/pro/sections/team";
//@import "node_modules/mdbootstrap-pro/scss/pro/sections/testimonials";
//@import "node_modules/mdbootstrap-pro/scss/pro/sections/magazine";
//@import "node_modules/mdbootstrap-pro/scss/pro/sections/pricing";
@import "node_modules/mdbootstrap-pro/scss/pro/sections/contacts";

// Portfolio styles
//@import "portfolio/general";

@import "_custom-styles";