section.team-section {
    ul.contact-informations {
        @extend .list-unstyled;
    }
    a.icons-sm {
        font-size: 1rem;
        color: black;
    }
    .role {
        @extend .h6;
        @extend .grey-text;
        @extend .font-weight-bold;
        @extend .mb-3;
    }
}
.sidebar {
    .contact-informations.main {
        @extend .h5;
    }
    ul.contact-informations {
        @extend .list-unstyled;
        li {
            line-height: 2;
        }
    }
}
.map-container {
    overflow:hidden;
    padding-bottom:56.25%;
    position:relative;
    height:0;
}
.map-container iframe {
    left:0;
    top:0;
    height:100%;
    width:100%;
    position:absolute;
}
// Contact section
.contact-section {
    .contact {
        background-color: $grey-darken-3;
        border-radius: 0;
        margin-right: -1px;
        svg {
            fill: currentColor;
        }
        a {
            color: white;
            &:hover {
                text-decoration: underline;
            }
        }
        .social-icon {
            @extend .list-inline;
            @extend .list-unstyled;
            li {
                @extend .list-inline-item;
                a {
                    @extend .btn-floating;
                    @extend .btn-lg;
                    .fab {
                        color: white;
                    }
                }
            }
        }
    }
}
