/* Sidebar */
#sidebar {
    @include make-col(4);
    /*     @extend .d-none;
    @extend .d-lg-block; */
    //@extend .mt-5;
    padding: 0 20px;
    @include media-breakpoint-down(md) {
        @include make-col(12);
        padding: 0 15px;
    }
}
.module-sidemenu {
    @include media-breakpoint-down(md) {
        display: none;
    }
    padding: 0 15px;
    ul {
        li.nav-item {
            border-bottom: 1px solid #ccc;
            padding: 3px 0;
            a {
                font-weight: bold;
            }
            &.active {
                a {
                    color: rgb(164, 0, 0);
                }
            }

        }
    }
}
.sidebar {
    img {
        @extend .img-fluid;
    }
    .card {
        @extend .mb-3;
        .card-header {
            @extend .h3-responsive;
            @extend .white;
            @extend .blue-text;
            font-weight: 600;
        }
        .card-body {
            > a.btn {
                @extend .float-right;
            }
            .btn {
                @extend .btn-primary;
            }
        }
    }
    table.openhours {
        //@extend .white-text;
        max-width: 300px;
        margin-bottom: 10px;
        @include media-breakpoint-down(sm) {
            margin: 0 auto;
        }
        td {
            font-weight: inherit;
            font-size: inherit;
        }
        td:first-of-type {
            width: 130px;
        }
    }
}
