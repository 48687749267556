/* purgecss start ignore */
.wow {
  visibility: hidden;
}
h1, h2, h3, h4, h5, h6 {
    text-transform: uppercase;
}
h1, h2, h3, h4, h5, h6 {
    &.ernryd {
        font-size: clamp(3rem, 6vw, 6rem);
        font-family: Conifer-Rough,Helvetica,Arial,sans-serif;
        letter-spacing: normal;
        font-weight: normal;
        line-height: 1em;
    }
}
.content {
    h1, h2, h3, h4, h5, h6 {
        &.ernryd {
            margin-bottom: 50px;
        }
    }
}
.blur {
    backdrop-filter: blur(4px);
}
body.index main {
    background-image: url(../img/bg/bg-mainbody.jpg);
    background-repeat: repeat-x;
}

a {
    color: $grey-darken-3;
}
img {
    @extend .img-fluid;
}

.feature-blurbs {
    @extend .container-fluid;
    background: #cd003a;
    color: white;
    padding: 30px 0;
    text-align: center;
    h3 {
        font-size: 14px;
    }
    .svg-icon {
        width: 50px;
        height: 50px;
        text-align: center;
        svg {
            width: 50px;
            height: 50px;
        }
    }
    
    .header {
        position: relative;
        margin-top: 30px;
        text-align: center;
        @include media-breakpoint-down(sm) {
            order: -1;
            margin-top: 0;
            margin-bottom: 30px;
        }
        &:before {
            z-index: 2;
            display: block;
            content: '';
            position: absolute;
            left: 12.5%;
            top: -25%;
            width: 75%;
            height: 20px;
            border: 1px solid #fff;
            border-top-width: 0px;
            @include media-breakpoint-down(sm) {
                left: 5%;
                width: 90%;
                border-left-width: 0px;
                border-right-width: 0px;
            }
        }
        h3 {
            display: inline-block;
            position: relative;
            z-index: 3;
            margin: 0;
            padding: 0 15px;
            text-align: center;
            font-size: 18px;
            line-height: normal;
            font-weight: 600;
            color: #fff;
            background-color: #cd003a;
        }
    }
}
/* purgecss end ignore */

// Your custom styles
@import "custom/_fancybox";
@import "custom/_footer";
@import "custom/_header";
@import "custom/_navbar";
@import "custom/_icon";
@import "custom/_carousel";
@import "custom/_forms";
@import "custom/_kontakt";
@import "custom/_omoss";
@import "custom/_personel";
@import "custom/_sidebar";
@import "custom/_slickslides";
//@import "custom/_tjanster";
@import "custom/cars/_all";
